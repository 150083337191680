import { useSelector } from "react-redux";

import colors from "../../../shared/color";

import "./style.scss";

export const ScrollsPy = () => {
    const { theme, lang } = useSelector((state) => state.pageSetting);

    return (
        <div className = "scrollsPy" id = "scrollsPy" style = {{
            color: (theme ? colors.black.fontColor : colors.light.fontColor),
        }}>
            <ul>
                <li><a href="#about_me_div" className = "list-group-item list-group-item-action">{ lang ? "Обо мне" : "About me" }</a></li>
                <li><a href="#experience_div" className = "list-group-item list-group-item-action">{ lang ? "Навыки" : "Skills" }</a></li>
                <li><a href="#project_link_div" className = "list-group-item list-group-item-action">{ lang ? "Проекты" : "Projects" }</a></li>
                <li><a href="#job_div" className = "list-group-item list-group-item-action">{ lang ? "Опыт работы" : "Work experience" }</a></li>
                <li><a href="#certefies_div" className = "list-group-item list-group-item-action">{ lang ? "Сертефикаты" : "Certificates" }</a></li>
                <li><a href="#social_div" className = "list-group-item list-group-item-action">{ lang ? "Как связаться?" : "How to contact?" }</a></li>
            </ul>
        </div>
    )
}