import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
// import { Navigation, FreeMode } from "swiper";

import colors from "../../../shared/color";
import BaseUrl from "../../../servies/url";

import "./style.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const ProjectCard = ({card, defaultImg}) => {
    const [onHover, setHover] = useState(false);
    
    const { theme, lang } = useSelector((state) => state.pageSetting);
    
    return (
        <div className = "cardProject" style={{
            backgroundColor: (theme ? colors.black.darkGray : colors.light.darkGray),
            color: (theme ? colors.black.fontColor : colors.light.fontColor),
        }}>
            <div className = "imgCard" style={{
                backgroundImage: `url(${BaseUrl}${card.photo ? `${card.photo}` : defaultImg})`
            }} />

            
            <Swiper
                slidesPerView={3}
                spaceBetween={5}
                grabCursor={true}
                centeredSlides={false}
                className = "expirience_wrapper"
            >
                {
                    card.experience && (
                        card.experience.map(
                            (i) => (
                                <SwiperSlide className="swiper-slide">
                                    <div 
                                        className = "card__project-expirience"
                                        style = {{
                                            backgroundColor: (theme ? colors.black.expirienceBlack : colors.light.expirienceBlack),
                                        }}
                                    >{i.name}</div>
                                </SwiperSlide>
                            )
                        )
                    )
                }
            </Swiper>

            <h1>{card.name}</h1>
            <p className = "deskription">{card.deskription}</p>

            <div className = "bottomDiv">
                {
                    card.site ? (
                        <a href={card.site}>
                            <button className = "openInWeb" style={{
                                backgroundColor: (onHover ? ("transparent") : (theme ? "white" : "black")),
                                color: (
                                    onHover ? (theme ? colors.black.fontColor : colors.light.fontColor)
                                    : (theme ? colors.light.fontColor : colors.black.fontColor)
                                ),
                                borderColor: (onHover && (theme ? "white" : "black"))
                            }} onMouseEnter = {
                                () => {
                                    setHover(true);
                                }
                            } onMouseLeave={
                                () => {
                                    setHover(false);
                                }
                            }>{ lang ? "ПОСМОТРЕТЬ" : "SHOW" }</button>
                        </a>
                    ) : (
                        <p>{ lang ? "ССЫЛКА В РАЗРАБОТКЕ" : "LINK IN DEVELOPMENT" }</p>
                    )
                }
            </div>
        </div>
    )
}

export default ProjectCard;