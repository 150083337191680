import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import $api from "../../servies/api";
import BaseUrl from "../../servies/url";
import colors from "../../shared/color";

import "./style.scss";

const ProjectLinkCards = () => {
    const [category, setCategory] = useState([]);

    const navigate = useNavigate();
    const { theme, lang } = useSelector((state) => state.pageSetting);

    const fetchProject = useCallback(
        async () => {
            await $api
                .get("get_category")
                .then(res => {
                    setCategory(res.data);
                })
        }, []
    );

    useEffect(
        () => {
            fetchProject();
        }, []
    );

    return(
        <div id = "myProjects" style = {{
            backgroundColor: (theme ? colors.black.defaultGray : colors.light.defaultGray),
            color: (theme ? colors.black.fontColor : colors.light.fontColor),
        }}>
            <h1>{ lang ? "Мои проекты:" : "My projects:" }</h1>
            <p className = "anotation">{ lang ? "Карточки кликабельны" : "The cards are clickable" }</p>

            <div className = "cards">
                {
                    category.length ? (
                        category.map(
                            i => (
                                <div className = "iconCategory" key = {i.id} onClick={() => navigate(`/projects/${i.id}`)} style = {{
                                    backgroundImage: `url(${BaseUrl}${i.photo})`,
                                    backgroundBlendMode: (theme && "multiply"),
                                }} />
                            )
                        )
                    ) : (
                        <p>{ lang ? "В базе данных не хранятся категории" : "Categories are not stored in the database" }</p>
                    )
                }
            </div>
        </div>
    )
}

export default ProjectLinkCards;