// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projectList {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  width: 100%;
}
@media screen and (max-width: 765px) {
  .projectList {
    display: grid;
    grid-template-columns: none;
    gap: 0px;
    justify-content: center;
  }
}

.notFound {
  text-align: center;
  width: 100%;
  font: 200 18px Ubuntu;
}`, "",{"version":3,"sources":["webpack://./src/Components/pojectList/style.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,aAAA;EACA,qCAAA;EACA,SAAA;EAEA,WAAA;AAAJ;AAEI;EARJ;IASQ,aAAA;IACA,2BAAA;IACA,QAAA;IACA,uBAAA;EACN;AACF;;AAGA;EACI,kBAAA;EACA,WAAA;EACA,qBAAA;AAAJ","sourcesContent":[".projectList {\n    justify-content: center;\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 25px;\n\n    width: 100%;\n\n    @media screen and (max-width: 765px) {\n        display: grid;\n        grid-template-columns: none;\n        gap: 0px;\n        justify-content: center;\n    }\n\n}\n\n.notFound {\n    text-align: center;\n    width: 100%;\n    font: 200 18px Ubuntu;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
