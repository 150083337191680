import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import AboutMe from "../../Components/adoutMe";
import Experience from "../../Components/experience/experience";
import ProjectLinkCards from "../../Components/project/project";
import Certefies from "../../Components/certefies/certefies";
import SocialNetWork from "../../Components/socialNetwork/social";
import Job from "../../Components/job/Job";
import Menu from "../../Components/Menu";

import { setLang, setTheme } from "../../store/slices/pageSettingSlice";
import useMediaQuery from "../../shared/useMediaQuery";

import "./style.scss";


const Main = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["onDarkMode", "language"]);

    const matches = useMediaQuery("(max-width: 1024px)");
    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(setTheme(!cookies.onDarkMode));
        }, []
    )

    useEffect(
        () => {
            dispatch(setLang(!cookies.language));
        }, []
    )

    return(
        <>
            {
                matches ? ( 
                    <header>
                        <Menu setLang={setLang} setTheme={setTheme} />
                    </header>
                ) : (
                    <aside>
                        <Menu setLang={setLang} setTheme={setTheme} />
                    </aside>
                )
            }

            <main>
                <section className = "firstImg backImg" id = "about_me_div" >
                    <AboutMe />
                </section>

                <section className = "secondImg backImg" id = "experience_div" >
                    <Experience />
                </section>

                <section className = "secondImg backImg" id = "project_link_div" >
                    <ProjectLinkCards />
                </section>

                <section className="backImg" id="job_div">
                    <Job />
                </section>

                <section className = "certefiesImg backImg" id = "certefies_div" >
                    <Certefies />
                </section>

                <section className = "networkImg backImg" id = "social_div" >
                    <SocialNetWork />
                </section>
            </main>
        </>
    )
}

export default Main;