// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #919191;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: #00000000;
}

*::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #383838;
}

html {
  scroll-behavior: smooth;
}

body > iframe {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;EACE,UAAU;EACV,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf","sourcesContent":["*::-webkit-scrollbar-track {\n    border-radius: 5px;\n    background-color: #919191;\n}\n\n*::-webkit-scrollbar {\n  width: 5px;\n  background-color: #00000000;\n}\n\n*::-webkit-scrollbar-thumb {\n  border-radius: 15px;\n  background-color: #383838;\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\nbody > iframe {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
