import { useSelector } from "react-redux";

import ExperienceCard from "../Card/experienceCard/experienceCard";

import front from "../../assets/image/front.png";
import back from "../../assets/image/back.png";

import "./style.scss";

const Experience = () => {
    const { theme, lang } = useSelector((state) => state.pageSetting);

    return (
        <div className = "cardGroup">
            <ExperienceCard photo = { front } theme = { theme } >
                {
                    lang ? (
                        <p>В основном мой front-end стек - <b>React + Redux</b>. Имееться богатый опыт с <b>Rest API</b>. Имею навыки адптивной и резиновой вёрстки. Помимо навыков <b>TypeScript</b> и <b>Sass</b> я имею опыт работы с <b>JQuery</b> и <b>Bootstrap</b>.</p>
                    ) : (
                        <p>Basically my front-end stack is <b>React+ Redux</b>. There is a wealth of experience with <b>Rest API</b>. I have adaptive and rubber layout skills. In addition to <b>TypeScript</b> and <b>Sss</b> skills, I have experience working with <b>jQuery</b> and <b>Bootstrap</b>.</p>
                    )
                }
            </ExperienceCard>
            <ExperienceCard photo = { back } theme = { theme } >
                {
                    lang ? (
                        <p>Основными framework’ами, с которым я работаю являеются <b>Spring</b> и <b>Django</b>. Имею опыт работы с <b>Rest API</b>. Написание баз данных <b>MySQL</b> и <b>SQLite</b>.</p>
                    ) : (
                        <p>The main frameworks I work with are <b>Spring</b> and <b>Django</b>. I have experience working with <b>Rest API</b>. Writing databases <b>MySQL</b> and <b>SQLite</b>.</p>
                    )
                }
            </ExperienceCard>
        </div>
    )
}

export default Experience;