import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Main from "./Pages/Main";
import Projects from "./Pages/Project";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path = "/" element = {<Main />} />
                <Route path = "/projects/:id" element = {<Projects />} />
            </Routes>
        </Router>
    )
}

export default App;