import { useEffect, useState } from "react";
import useMediaQuery from "../../shared/useMediaQuery";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";

import { changeLang, changeTheme } from "../../store/slices/pageSettingSlice";
import colors from "../../shared/color";
import { ScrollsPy } from "./ScrollsPyList/srollspy";

import moon from "../../assets/moon.svg";
import sun from "../../assets/sun.svg";
import russ from "../../assets/russia_lang.svg";
import eng from "../../assets/english_lang.svg";

import "./style.scss";

const Menu = ({ setTheme, setLang }) => {
    const [cookies, setCookie, removeCookie] = useCookies(["onDarkMode", "language"]);

    const dispatch = useDispatch();
    const { theme, lang } = useSelector((state) => state.pageSetting);
    const matches = useMediaQuery("(max-width: 1024px)");


    return (
        <nav style = {{
            backgroundColor: (theme ? colors.black.defaultGray : colors.light.defaultGray )
        }}>
            <div className = "activity">
                <button className = "langSwitcher" onClick={
                    () => {
                        setLang(!lang);
                        setCookie("language", lang);
                        dispatch(changeLang());
                    }
                }>
                    <img src={lang ? eng : russ} alt="btnIco" />
                </button>

                <button className = "themeSwitcher" id = "theme" onClick={
                    () => {
                        setTheme(!theme);
                        setCookie("onDarkMode", theme);
                        dispatch(changeTheme());
                    }
                }>
                    <img src={theme ? moon : sun} alt="btnIco" />
                </button>
            </div>

            {
                !matches && <ScrollsPy theme = {theme} lang = {lang} />
            }
        </nav>
    )
}

export default Menu;