import listMonth from "./getMonth";

const data = (date) => {
    const data = new Date(date);

    if (date) {
        return `${data.getDate()} ${listMonth[data.getMonth()]} ${data.getFullYear()}`;
    } else {
        return "по сей день";
    }
}

export default data;