const colors = {
    black: {
        defaultGray: 'rgba(71, 71, 71, 1)',
        lightGray: 'rgb(97, 97, 97)',
        darkGray: 'rgba(54, 53, 53, 1)',
        expirienceBlack: 'rgb(17, 17, 17)',
        selectColor: 'rgba(134, 183, 255, 1)',
        fontColor: 'white',
    },
    
    light: {
        defaultGray: 'rgba(234, 234, 234, 1)',
        lightGray: 'rgb(167, 167, 167)',
        darkGray: 'rgba(189, 189, 189, 1)',
        expirienceBlack: 'rgb(156, 156, 156)',
        selectColor: 'rgba(49, 131, 255, 1)',
        fontColor: 'black',
    },
}

export default colors;