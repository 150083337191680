// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#cardAboutMe {
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  text-align: center;
  color: white;
  border-radius: 15px;
}
@media screen and (max-width: 1024px) {
  #cardAboutMe {
    margin-top: 70px;
    width: 95%;
  }
}
#cardAboutMe .header {
  font: 400 50px Ubuntu;
}
@media screen and (max-width: 1024px) {
  #cardAboutMe .header {
    font: 400 39px Ubuntu;
  }
}
#cardAboutMe .myName {
  font: 400 19px Ubuntu;
  position: relative;
  bottom: 45px;
}
#cardAboutMe .aboutMeDesk {
  font: 400 20px Ubuntu;
  text-align: left;
  text-indent: 10px;
  margin: 50px 70px 150px 70px;
  position: relative;
  bottom: 55px;
}
@media screen and (max-width: 1024px) {
  #cardAboutMe .aboutMeDesk {
    font: 400 17px Ubuntu;
    width: 90%;
    margin: 50px auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/adoutMe/style.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,wBAAA;EAAA,mBAAA;EAEA,aAAA;EACA,uBAAA;EACA,kBAAA;EAGA,YAAA;EACA,mBAAA;AAFJ;AAII;EAZJ;IAaQ,gBAAA;IACA,UAAA;EADN;AACF;AAGI;EACI,qBAAA;AADR;AAGQ;EAHJ;IAIQ,qBAAA;EAAV;AACF;AAGI;EACI,qBAAA;EACA,kBAAA;EACA,YAAA;AADR;AAII;EACI,qBAAA;EACA,gBAAA;EACA,iBAAA;EAEA,4BAAA;EACA,kBAAA;EACA,YAAA;AAHR;AAKQ;EATJ;IAUQ,qBAAA;IACA,UAAA;IACA,iBAAA;EAFV;AACF","sourcesContent":["#cardAboutMe {\n    width: 80%;\n    height: fit-content;\n\n    display: flex;\n    justify-content: center;\n    text-align: center;\n    // margin-top: 100px;\n\n    color: white;\n    border-radius: 15px;\n\n    @media screen and (max-width: 1024px) {\n        margin-top: 70px;\n        width: 95%;\n    }\n\n    .header {\n        font: 400 50px Ubuntu;\n\n        @media screen and (max-width: 1024px) {\n            font: 400 39px Ubuntu;\n        }\n    }\n\n    .myName {\n        font: 400 19px Ubuntu;\n        position: relative;\n        bottom: 45px;\n    }\n\n    .aboutMeDesk {\n        font: 400 20px Ubuntu;\n        text-align: left;\n        text-indent: 10px;\n\n        margin: 50px 70px 150px 70px;\n        position: relative;\n        bottom: 55px;\n\n        @media screen and (max-width: 1024px) {\n            font: 400 17px Ubuntu;\n            width: 90%;\n            margin: 50px auto;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
