// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css\" rel=\"stylesheet\" integrity=\"sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollsPy {
  font: 400 120% Ubuntu;
  margin-top: 50px;
}
.scrollsPy ul {
  line-height: 55px;
  list-style: none;
}
.scrollsPy ul a {
  color: inherit;
  text-decoration: inherit;
}
.scrollsPy ul .active {
  color: rgb(68, 208, 255);
}`, "",{"version":3,"sources":["webpack://./src/Components/Menu/ScrollsPyList/style.scss","webpack://./src/shared/variable.scss"],"names":[],"mappings":"AAGA;EACI,qBAAA;EACA,gBAAA;AADJ;AAGI;EACI,iBAAA;EACA,gBAAA;AADR;AAGQ;EACI,cAAA;EACA,wBAAA;AADZ;AAIQ;EACI,wBCXA;ADSZ","sourcesContent":["@import 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css\" rel=\"stylesheet\" integrity=\"sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN';\n@import '../../../shared/variable.scss';\n\n.scrollsPy {\n    font: 400 120% Ubuntu;\n    margin-top: 50px;\n\n    ul {\n        line-height: 55px;\n        list-style: none;\n\n        a {\n            color: inherit;\n            text-decoration: inherit;\n        }\n\n        .active {\n            color: $hoverBlue;\n        }\n    }\n}","@mixin img ($size) {\n    background-size: $size;\n    background-repeat: no-repeat;\n    background-position: center;\n}\n\n$hoverBlue: rgb(68, 208, 255);\n$hoverBorder: 4px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
