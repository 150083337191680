const listMonth = [
    ["янв."],
    ["фев."],
    ["марта"],
    ["апр."],
    ["мая"],
    ["июня"],
    ["июля"],
    ["авг."],
    ["сент."],
    ["окт."],
    ["нояб."],
    ["дек."],
]

export default listMonth;