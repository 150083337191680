// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-wrapper {
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  row-gap: 15px;
  padding-bottom: 25px;
  color: white;
  border-radius: 15px;
}
@media screen and (max-width: 1024px) {
  .job-wrapper {
    margin-top: 70px;
    width: 95%;
  }
}
.job-wrapper h1 {
  text-align: center;
  font: 200 44px Ubuntu;
}`, "",{"version":3,"sources":["webpack://./src/Components/job/style.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,wBAAA;EAAA,mBAAA;EAEA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,oBAAA;EAEA,YAAA;EACA,mBAAA;AADJ;AAGI;EAdJ;IAeQ,gBAAA;IACA,UAAA;EAAN;AACF;AAEI;EACI,kBAAA;EACA,qBAAA;AAAR","sourcesContent":[".job-wrapper {\n    width: 80%;\n    height: fit-content;\n\n    display: flex;\n    justify-content: center;\n    text-align: center;\n    flex-direction: column;\n    row-gap: 15px;\n    padding-bottom: 25px;\n\n    color: white;\n    border-radius: 15px;\n\n    @media screen and (max-width: 1024px) {\n        margin-top: 70px;\n        width: 95%;\n    }\n\n    h1 {\n        text-align: center;\n        font: 200 44px Ubuntu;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
