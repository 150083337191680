// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Ubuntu);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main {
  position: absolute;
  left: 0px;
  top: 0px;
  margin-left: 10%;
  font-family: Ubuntu;
}
@media screen and (max-width: 1024px) {
  main {
    width: 100%;
    margin-left: 0%;
  }
}
@media screen and (max-width: 350px) {
  main {
    width: 100%;
    margin-left: 0%;
  }
}
main section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
@media screen and (max-widht: 1024px) {
  main section {
    width: 100%;
  }
}
main .backImg {
  background-color: rgba(0, 0, 0, 0.385);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Main/style.scss","webpack://./src/shared/variable.scss"],"names":[],"mappings":"AAGA;EACI,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gBAAA;EAEA,mBAAA;AAFJ;AAII;EARJ;IASQ,WAAA;IACA,eAAA;EADN;AACF;AAGI;EAbJ;IAcQ,WAAA;IACA,eAAA;EAAN;AACF;AAEI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,WAAA;EACA,iBAAA;EACA,wBAAA;EAAA,mBAAA;AADR;AAGQ;EATJ;IAUQ,WAAA;EAAV;AACF;AAGI;EACI,sCAAA;EACA,4BAAA;ECpCJ,sBDqCiB;ECpCjB,4BAAA;EACA,2BAAA;ADoCJ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Ubuntu\");\n@import \"../../shared/variable.scss\";\n\nmain {\n    position: absolute;\n    left: 0px;\n    top: 0px;\n    margin-left: 10%;\n\n    font-family: Ubuntu;\n\n    @media screen and (max-width: 1024px) {\n        width: 100%;\n        margin-left: 0%;\n    }\n\n    @media screen and (max-width: 350px) {\n        width: calc(100%);\n        margin-left: 0%;\n    }\n\n    section {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n\n        width: 100%;\n        min-height: 100vh;\n        height: fit-content;\n\n        @media screen and (max-widht: 1024px) {\n            width: 100%;\n        }\n    }\n\n    .backImg {\n        background-color: rgba(0, 0, 0, 0.385);\n        background-attachment: fixed;\n        @include img(cover);\n    }\n}","@mixin img ($size) {\n    background-size: $size;\n    background-repeat: no-repeat;\n    background-position: center;\n}\n\n$hoverBlue: rgb(68, 208, 255);\n$hoverBorder: 4px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
