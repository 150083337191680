import { useSelector } from "react-redux";
import BaseUrl from "../../../servies/url";
import colors from "../../../shared/color";
import data from "../../../shared/date";

import "./style.scss";

const JobCard = ({ job }) => {
    const { theme } = useSelector((state) => state.pageSetting);

    return (
        <div className="job__card" style={{
            backgroundColor: (theme ? colors.black.lightGray : colors.light.lightGray),
            color: (theme ? colors.black.fontColor : colors.light.fontColor),
        }}>
            <div className="job__card-top">
                <div className="job__card-left" style={{
                    backgroundImage: `url(${BaseUrl}${job?.imgUrl})`
                }} />

                <div className="job__card-right">
                    <h3>{job?.name}</h3>
                    <p className="job__card-deskription">{job?.deskription}</p>
                </div>
            </div>

            <div className="date__job">
                <div className="date__job-start" style={{
                    backgroundColor: (theme ? colors.black.darkGray : colors.light.darkGray),
                    color: (theme ? colors.black.fontColor : colors.light.fontColor),
                }}>
                    <p>{data(job?.dateOfJob)}</p>
                </div>

                -

                <div className="date__job-end" style={{
                    backgroundColor: (theme ? colors.black.darkGray : colors.light.darkGray),
                    color: (theme ? colors.black.fontColor : colors.light.fontColor),
                }}>
                    <p>{data(job?.dateOfStop)}</p>
                </div>
            </div>
        </div>
    )
}

export default JobCard;