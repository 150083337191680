import { createSlice } from "@reduxjs/toolkit";

import Cookies from "js-cookie";


const initialState = {
    theme: Cookies.get("onDarkMode"),
    lang: Cookies.get("language")
}


export const pageSettingSlice = createSlice({
    name: "page-settings",
    initialState,
    reducers: {
        changeTheme: (state, action) => {
            state.theme = !state.theme;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
        changeLang: (state, action) => {
            state.lang = !state.lang;
        },
        setLang: (state, action) => {
            state.lang = action.payload;
        },
    }
})


export const { changeLang, changeTheme, setLang, setTheme } = pageSettingSlice.actions;

export default pageSettingSlice.reducer;