import { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux";

import $api from "../../servies/api";
import ProjectCard from "../Card/projectCard/projectCard";

import "./style.scss";

const ProjectList = ({ id }) => {
    const [projects, setProjects] = useState([]);
    const [infoCategory, setInfoCategory] = useState([]);

    const { theme, lang } = useSelector((state) => state.pageSetting);

    const fetchProjects = useCallback(
        async () => {
            await $api
                .get(
                    `get_category/${id}`
                )
                .then(
                    (res) => {
                        setProjects(res.data);
                    }
                )
                .catch(
                    err => console.log(err)
                );
        }
    )

    useEffect(
        () => {
            fetchProjects();
        }, []
    )

    const fetchCategoryInfo = useCallback(
        async () => {
            await $api
                .get(`get_category/one/${id}`)
                .then(
                    res => {
                        setInfoCategory(res.data);
                    }
                )
                .catch(
                    err => console.log(err)
                )
        }
    )

    useEffect(
        () => {
            fetchCategoryInfo();
        }, []
    )

    return (
        <>
            {
                projects.length ? (
                    <div className = "projectList">
                        {
                            projects.map(
                                i => <ProjectCard key={i.id} card = {i} theme = {theme} defaultImg = {infoCategory.photo} lang = {lang} />
                            )
                        }
                    </div>
                ) : (
                    <p className="notFound">{ lang ? "Не найдено" : "Not found" }</p>
                )
            }
        </>
    )
}

export default ProjectList;