import { configureStore } from "@reduxjs/toolkit";
import pageSettingReducer from "./slices/pageSettingSlice";


const store = configureStore({
    reducer: {
        pageSetting: pageSettingReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
        serializableCheck: false,
    }),
})


export default store;