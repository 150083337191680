import { useSelector } from "react-redux";

import colors from "../../../shared/color";

import "./style.scss";

const ExperienceCard = ({ children, photo }) => {
    const { theme } = useSelector((state) => state.pageSetting);

    return (
        <div className = "experienceCard" style={{
            backgroundColor: (theme ? colors.black.defaultGray : colors.light.defaultGray),
            color: (theme ? colors.black.fontColor : colors.light.fontColor),
        }}>
            <div className = "img" style = {{
                backgroundImage: `url(${photo})`,
                backgroundBlendMode: (theme && "multiply"),
            }} />

            <div className = "text">
                { children }
            </div>
        </div>
    )
}

export default ExperienceCard;