import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";

import $api from "../../servies/api";
import BaseUrl from "../../servies/url";
import colors from "../../shared/color";
import getTheme from "../../servies/getTheme";

import "./style.scss";

const SocialNetWork = () => {
    const [social, setSocial] = useState([]);

    const { theme, lang } = useSelector((state) => state.pageSetting);

    const fetchSocial = useCallback(
        async () => {
            await $api
                .get("social_network")
                .then(
                    res => {
                        setSocial(res.data);
                    }
                )
                .catch(
                    err => console.log(err)
                )
        }, []
    );

    useEffect(
        () => {
            fetchSocial();
        }, []
    )

    return(
        <div className = "socialCard" style={{
            backgroundColor: (theme ? colors.black.defaultGray : colors.light.defaultGray),
            color: (theme ? colors.black.fontColor : colors.light.fontColor),
        }}>
            <h1>{ lang ? "Как со мной связаться?" : "How can you contact me?" }</h1>
            <h3>{ lang ? "Мои социальные сети:" : "My social networks:" }</h3>
            <div className = "socialGroup">
                {
                    social.length ? (
                        social.map(
                            i => (
                                <a href={i.site} key={i.id}>
                                    <div className = "iconSocial" id = {i.name} style={{
                                        backgroundImage: `url(${(theme ? (`${BaseUrl}/${i.photoDark}`) : (`${BaseUrl}/${i.photoLight}`))})`,
                                    }} onMouseEnter={
                                        () => {
                                            if (window.matchMedia("(hover: hover)").matches) {
                                                $(`#${i.name}`).css(
                                                    {
                                                        "backgroundImage": `url(${BaseUrl}${i.photo})`, 
                                                    }
                                                );
                                            }
                                        }
                                    }
                                    onMouseLeave={() => {
                                        if (window.matchMedia("(hover: hover)").matches) {
                                            $(`#${i.name}`).css(
                                                {
                                                    "backgroundImage": `url(${(theme ? (`${BaseUrl}/${i.photoDark}`) : (`${BaseUrl}/${i.photoLight}`))})`, 
                                                }
                                            );
                                        }
                                    }} />
                                </a>
                            )
                        )
                    ) : (
                        <p>{ lang ? "В базе данных не хранятся данные о социальных сетях" : "The database does not store data about social networks" }</p>
                    )
                }
            </div>
        </div>
    )
}

export default SocialNetWork;