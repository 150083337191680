// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experienceCard {
  width: 430px;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 33px;
  margin: 15px;
  border-radius: 15px;
}
@media screen and (max-width: 1144px) {
  .experienceCard {
    width: 30%;
  }
}
@media screen and (max-width: 1024px) {
  .experienceCard {
    width: 430px;
  }
}
.experienceCard .img {
  width: 100%;
  height: 228px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px 15px 0px 0px;
  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.099);
}
@media screen and (hover: hover) {
  .experienceCard .img:hover {
    background-color: rgba(0, 0, 0, 0.188);
  }
}
.experienceCard .text {
  margin: 23px 40px 38px 33px;
  height: 355px;
  text-overflow: ellipsis;
  font: 300 24px Ubuntu;
  text-indent: 24px;
}
@media screen and (max-width: 1144px) {
  .experienceCard .text {
    font: 300 20px Ubuntu;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Card/experienceCard/style.scss","webpack://./src/shared/variable.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,wBAAA;EAAA,mBAAA;EAEA,oBAAA;EACA,YAAA;EAEA,mBAAA;AAHJ;AAKI;EATJ;IAUQ,UAAA;EAFN;AACF;AAII;EAbJ;IAcQ,YAAA;EADN;AACF;AAGI;EACI,WAAA;EACA,aAAA;ECpBJ,sBDsBiB;ECrBjB,4BAAA;EACA,2BAAA;EDqBI,gCAAA;EACA,gBAAA;EACA,sCAAA;AAAR;AAEQ;EACI;IACI,sCAAA;EAAd;AACF;AAII;EACI,2BAAA;EACA,aAAA;EAEA,uBAAA;EACA,qBAAA;EACA,iBAAA;AAHR;AAKQ;EARJ;IASQ,qBAAA;EAFV;AACF","sourcesContent":["@import \"../../../shared/variable.scss\";\n\n.experienceCard {\n    width: 430px;\n    height: fit-content;\n    \n    padding-bottom: 33px;\n    margin: 15px;\n\n    border-radius: 15px;\n\n    @media screen and (max-width: 1144px){\n        width: 30%;\n    }\n\n    @media screen and (max-width: 1024px) {\n        width: 430px;\n    }\n\n    .img {\n        width: 100%;\n        height: 228px;\n\n        @include img(cover);\n        border-radius: 15px 15px 0px 0px;\n        transition: 0.3s;\n        background-color: rgba(0, 0, 0, 0.099);\n\n        @media screen and (hover: hover) {\n            &:hover {\n                background-color: rgba(0, 0, 0, 0.188);\n            }\n        }\n    }\n\n    .text {\n        margin: 23px 40px 38px 33px;\n        height: 355px;\n\n        text-overflow: ellipsis;\n        font: 300 24px Ubuntu;\n        text-indent: 24px;\n\n        @media screen and (max-width: 1144px){\n            font: 300 20px Ubuntu;\n        }\n    }\n}","@mixin img ($size) {\n    background-size: $size;\n    background-repeat: no-repeat;\n    background-position: center;\n}\n\n$hoverBlue: rgb(68, 208, 255);\n$hoverBorder: 4px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
