import { useSelector } from "react-redux";

import colors from "../../shared/color";

import "./style.scss";

const AboutMe = () => {
    const { theme, lang } = useSelector((state) => state.pageSetting);

    return (
        <div id = "cardAboutMe" style={{
            backgroundColor: (theme ? colors.black.defaultGray : colors.light.defaultGray),
            color: (theme ? colors.black.fontColor : colors.light.fontColor)
        }}>
            <div>
                <p className = "header">{ lang ? "Full-stack разработчик" : "Full-stack developer" }</p>
                <p className = "myName">{ lang ? "Александр Новиков" : "Alexander Novikov" }</p>
                {
                    lang ? (
                        <p className = "aboutMeDesk"> Имею опыт как верстки, так и написания сайтов (React + TypeScript и Sass). Я сертифицированный разработчик с весомым опытом работы с Bootstrap и React. Так же имею опыт создания телеграмм-ботов (aiogram или telebot). Помимо этого имею большой опыт в написании back-end части сайта с использованием Spring и Django, проходя обучение под строгим контролем преподавателей госсударственных курсов. В компаниях MayBe и vseTUT я получал много отзывов, что может стать подтверждёнными кейсами, которые я могу предоставить по вашему запросу.</p>
                    ) : (
                        <p className = "aboutMeDesk"> I have experience in both layout and writing websites (React + TypeScript and Sas). I am a certified developer with significant experience working with Bootstrap and React. I also have experience in creating telegram bots (aiogram or telebot). In addition, I have extensive experience in writing the back-end part of the site using Spring and Django, undergoing training in it under the strict supervision of teachers of government courses. I have received a lot of feedback from MayBe and vseTUT companies, which may become confirmed cases that I can provide upon your request.</p>
                    )
                }
            </div>
        </div>
    )
}

export default AboutMe;