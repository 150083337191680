import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import $api from "../../servies/api";
import BaseUrl from "../../servies/url";
import colors from "../../shared/color";

import "./style.scss";

const Certefies = () => {
    const { theme, lang } = useSelector((state) => state.pageSetting);

    const [certefies, setCertefies] = useState([]);

    const fetchCertefies = useCallback(
        async () => {
            await $api
                .get(
                    "sertefies"
                )
                .then(
                    res => {
                        setCertefies(res.data);
                    }
                )
                .catch(
                    err => console.log(err)
                )
        }, []
    );

    useEffect(
        () => {
            fetchCertefies();
        }, []
    );

    return (
        <div className = "certefiesCard" style={{
            backgroundColor: (theme ? colors.black.defaultGray : colors.light.defaultGray),
            color: (theme ? colors.black.fontColor : colors.light.fontColor),
        }}>
            <h1>{ lang ? "Мои сертефикаты:" : "My certificates:" }</h1>

            <p>{ lang ? "Как я и писал ранее я имею сертефикаты, подтверждающие мои навыки (карточки кликабельны)" : "As I wrote earlier, I have certificates confirming my skills (the cards are clickable)" }</p>

            {
                certefies.length ? (
                    <div className = "container text-center">
                        <div className = "groupCertefies">
                            {
                                certefies.map(
                                    i => (
                                        <div key={i.id}>
                                            <p>{i.name}:</p>
                                            <a href={ i.site ? `${i.site}` : `${BaseUrl}/${i.photo}` }>
                                                <div className="certifies" style={{
                                                    backgroundImage: `url(${BaseUrl}/${i.photo})`
                                                }} />
                                            </a>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <p>{ lang ? "В базе данных не хранятся данные о сертефикатах" : "The database does not store data about certificates" }</p>
                )
            }
        </div>
    )
}

export default Certefies;