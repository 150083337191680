import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Menu from "../../Components/Menu";
import ProjectList from "../../Components/pojectList";
import colors from "../../shared/color";
import useMediaQuery from "../../shared/useMediaQuery";
import { setLang, setTheme } from "../../store/slices/pageSettingSlice";

import "./style.scss";

const Projects = () => {
    const { theme, lang } = useSelector((state) => state.pageSetting);

    const { id } = useParams();

    const matches = useMediaQuery("(max-widht: 1024px)");
    
    return (
        <>
            {
                matches ? ( 
                    <header>
                        <Menu setLang={setLang} setTheme={setTheme} />
                    </header>
                ) : (
                    <aside>
                        <Menu setLang={setLang} setTheme={setTheme} />
                    </aside>
                )
            }


            <main>
                <section className = "firstImg backImg">
                    <div className = "projectListCard" style={{
                        backgroundColor: (theme ? colors.black.defaultGray : colors.light.defaultGray),
                        color: (theme ? colors.black.fontColor : colors.light.fontColor),
                    }}>
                        <h1>{ lang ? "Мои проекты:" : "My projects:" }</h1>
                        <ProjectList id={id} />
                    </div>
                </section>
            </main>
        </>
    )
}

export default Projects;