import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import colors from "../../shared/color";

import "./style.scss";
import $api from "../../servies/api";
import JobCard from "../Card/jobCard/JobCard";

const Job = () => {
    const [jobs, setJobs] = useState([]);

    const { theme, lang } = useSelector((state) => state.pageSetting);


    const fetchJobs = useCallback(
        async () => {
            await $api
                .get("job/all")
                .then(
                    res => {
                        setJobs(res.data);
                    }
                )
                .catch(
                    err => console.log(err)
                )
        }
    )


    useEffect(
        () => {
            fetchJobs();
        }, []
    )


    return(
        <div className="job-wrapper" style = {{
            backgroundColor: (theme ? colors.black.defaultGray : colors.light.defaultGray),
            color: (theme ? colors.black.fontColor : colors.light.fontColor),
        }}>
            <h1>{ lang ? "Мой опыт работы:" : "My work experience:" }</h1>

            {
                jobs.length ? (
                    jobs.map(
                        (i) => <JobCard job={i} theme={theme} key={i.id} />
                    )
                ) : (
                    <p>{ lang ? "В базе данных не хранятя данные об опыте работы" : "The database does not store work experience data" }</p>
                )
            }
        </div>
    )
}

export default Job;